

















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PageFooter extends Vue {
  get year(): number {
    return new Date().getFullYear();
  }
}
