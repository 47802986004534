import Vue from "vue";

// Tabelas
import ComissoesTable from "@/views/app/Comissoes/components/ComissoesTable.vue";
import SaquesTable from "@/views/app/Saques/components/SaquesTable.vue";
Vue.component('comissoes-table', ComissoesTable);
Vue.component('saques-table', SaquesTable);

// Componentes das Tabelas
import Pagination from '@/components/Tabelas/Pagination.vue';
import FormatDate from "@/components/Tabelas/Celulas/FormatDate.vue";
import MoskitField from '@/components/Tabelas/Celulas/MoskitField.vue';
import IndicadorCell from '@/components/Tabelas/Celulas/IndicadorCell.vue';
import FormatMoney from '@/components/Tabelas/Celulas/FormatMoney.vue';
Vue.component('pagination', Pagination);
Vue.component('format-date', FormatDate);
Vue.component('moskit-field', MoskitField);
Vue.component('indicador-cell', IndicadorCell);
Vue.component('format-money', FormatMoney);

// Componentes dos filtros
import BtnFiltros from '@/components/Filtros/BtnFiltros.vue';
import FiltrosSidebar from '@/components/Filtros/Sidebar.vue';
Vue.component('btn-filtros', BtnFiltros);
Vue.component('filtros-sidebar', FiltrosSidebar);

// Componentes Gerais
import BtnTimed from "@/components/BtnTimed.vue";
Vue.component('btn-timed', BtnTimed);
