
































































































































import { Component, Vue } from 'vue-property-decorator';
import { Auth } from '@/plugins/api';

@Component
export default class Login extends Vue {
  public showPassword = false;
  public validate = false;
  public progress = false;

  public login: PostLoginRequest = {};
  public state: LoginState = { email: null, senha: null };
  public errors = {
    email: '',
    senha: '',
  };

  get passwordType(): string {
    return this.showPassword ? 'text' : 'password';
  }

  get toggleIcon(): string {
    return this.showPassword ? 'mdi-eye-off' : 'mdi-eye';
  }

  get loginInvalid(): boolean {
    return 'invalid' in this.$route.query;
  }

  get loggedOut(): boolean {
    return 'loggedOut' in this.$route.query;
  }

  get headerBgVariant(): string | null {
    if (this.loginInvalid) return 'danger';
    if (this.loggedOut) return 'info';
    return null;
  }

  get headerTextVariant(): string | null {
    if (this.loginInvalid || this.loggedOut) return 'white';
    return null;
  }

  get headerIcon(): string {
    if (this.loginInvalid) return 'mdi-alert-octagon';
    if (this.loggedOut) return 'mdi-account-arrow-right';
    return 'mdi-human-greeting';
  }

  mounted(): void {
    document.body.classList.add('bg-primary');
    document.body.classList.add('bg-pattern');
  }

  destroyed(): void {
    document.body.classList.remove('bg-primary');
    document.body.classList.remove('bg-pattern');
  }

  togglePassword(): void {
    this.showPassword = !this.showPassword;
  }

  submit(): void {
    this.validate = true;
    this.progress = true;
    this.clearErrors();
    if (this.validData) {
      Auth.instance
        .login(this.login)
        .then((response: GetLoginResponse) => {
          this.progress = false;
          if (response.success) {
            this.$router.push('/');
          } else {
            switch (response.code) {
              case 'email':
                this.state.email = false;
                this.errors.email = response.message;
                break;
              case 'senha':
                this.state.senha = false;
                this.errors.senha = response.message;
                break;
            }
          }
        })
        .finally(() => {
          this.progress = false;
        });
    } else {
      this.progress = false;
    }
  }

  get validData(): boolean {
    if (
      this.login.email === undefined ||
      this.login.email.trim().length === 0
    ) {
      return false;
    }
    if (
      this.login.senha === undefined ||
      this.login.senha.trim().length === 0
    ) {
      return false;
    }
    return true;
  }

  get stateEmail(): boolean | null {
    if (!this.validate) return null;
    if (
      this.login.email === undefined ||
      this.login.email.trim().length === 0
    ) {
      return false;
    }
    return this.state.email;
  }

  get stateSenha(): boolean | null {
    if (!this.validate) return null;
    if (
      this.login.senha === undefined ||
      this.login.senha.trim().length === 0
    ) {
      return false;
    }
    return this.state.senha;
  }

  get invalidEmailFeedback(): string {
    if (!this.validate) return '';
    if (
      this.login.email === undefined ||
      this.login.email.trim().length === 0
    ) {
      return 'Email é obrigatório';
    }
    return this.errors.email;
  }

  get invalidSenhaFeedback(): string {
    if (!this.validate) return '';
    if (
      this.login.email === undefined ||
      this.login.email.trim().length === 0
    ) {
      return 'Senha é obrigatória';
    }
    return this.errors.senha;
  }

  clearErrors(): void {
    this.state = {
      email: null,
      senha: null,
    };
    this.errors = {
      email: '',
      senha: '',
    };
  }
}
