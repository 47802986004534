














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SidebarMenuItem extends Vue {
  @Prop() private data!: ISidebarMenuItem;

  get active(): boolean {
    return this.$route.path.startsWith(this.route);
  }

  get title(): string {
    return this.data.title;
  }

  get route(): string {
    return this.data.href;
  }

  get icon(): string {
    return this.data.icon;
  }

  get counter() {
    return this.$store.state.sidebar[this.route] ?? 0;
  }

  navigate(): void {
    this.$router.push(this.route);
  }
}
