






























































































import { Component, Vue, Ref, Prop } from 'vue-property-decorator';
import { BTable, BvTableCtxObject } from 'bootstrap-vue';
import { eventOff, eventOn } from 'vue-context/src/js/utils';
import { Saque, Indicador } from '@/plugins/api';
import { columns } from './tabela';
import get from 'bootstrap-vue/src/utils/get';
import SaquesExportar from './Exportar.vue';
import SaquesContext from './ContextMenu.vue';

@Component({
  components: {
    SaquesExportar,
    SaquesContext,
  },
})
export default class ComissoesTable extends Vue {
  @Prop(Number) public readonly indicadorId: number | undefined;
  @Ref() readonly table!: BTable;
  @Ref() readonly context!: SaquesContext;
  public loading = false;
  public currentPage = 1;
  public totalRows = 1;
  public perPage = 50;

  public columns = columns;
  public soma = {} as ISaque;
  public getField = get;

  get mode(): string {
    if (this.indicadorId !== undefined) {
      return 'indicador';
    }
    return 'geral';
  }

  get filtros(): IFiltroValues[] {
    return this.$store.getters.filtrosSelecionados;
  }

  mounted(): void {
    eventOn(this.table.$el, 'scroll', this.context.menu.close);
    this.$root.$on('filtros', this.refresh);
  }

  destroy(): void {
    eventOff(this.table.$el, 'scroll', this.context.menu.close);
    this.$root.$off('filtros', this.refresh);
  }

  provider(
    context: BvTableCtxObject,
    callback: (data: ISaque[]) => void
  ): void {
    this.loading = true;
    const request = {
      params: {
        page: context.currentPage,
        size: context.perPage,
        sortBy: context.sortBy,
        sortDesc: context.sortDesc ? 'DESC' : 'ASC',
        filters: this.filtros,
      },
    };

    let saques: Promise<GetSaquesResponse>;
    if (this.indicadorId !== undefined) {
      saques = Indicador.getSaques(this.indicadorId, request);
    } else {
      saques = Saque.get(request);
    }

    saques
      .then((response: GetSaquesResponse) => {
        const saques = response?.data ?? [];
        saques.forEach((saque: ISaque) => {
          saque._rowVariant = Vue.filter('SaqueCor')(saque.situacao);
        });

        this.soma = saques.reduce((result, item) => {
          const current = JSON.parse(JSON.stringify(result ?? {})) as ISaque;

          current.comissoes.total =
            parseFloat(current?.comissoes?.total?.toString() ?? '0') +
            parseFloat(item?.comissoes?.total?.toString() ?? '0');
          return current;
        }, {comissoes: {total: 0}} as ISaque);

        callback(saques);
        this.totalRows = response?.metadata.total ?? 0;
      })
      .catch((error) => {
        callback([]);
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public refresh(): void {
    this.table.refresh();
  }

  public clearSelection(): void {
    this.table?.clearSelected();
  }

  public contextMenu(item: ISaque, data: number, event: MouseEvent): void {
    event.preventDefault();
    this.table.selectRow(data);
    this.context.open(event, item);
  }
}
