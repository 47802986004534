












import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class FormatMoney extends Vue {
  @Prop() public readonly data!: string;
  @Prop({ type: String, default: '-' }) public readonly text!: string;
}
