import Vue from 'vue';

import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
Vue.use(VueMoment, { moment });

import Vue2Filters from 'vue2-filters';
const vueFiltersOpts = {
  currency: {
    symbol: 'R$',
    decimalDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true,
    showPlusSign: false,
  },
};
Vue.use(Vue2Filters, vueFiltersOpts);

Vue.filter('nullCoalesce', (value: string, padrao: string) => value ?? padrao);

import './Comissao';
import './Fatura';
import './Saque';
import './Banco';
import './Indicador';
