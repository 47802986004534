import axios, { AxiosInstance } from 'axios';
import Api from './Api';

export default class Auth {
  private static _instance = new Auth();
  private _token: string | null;
  private _api: AxiosInstance;

  public constructor() {
    this._token = localStorage.getItem('token');
    this._api = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    });
  }

  public static get instance(): Auth {
    return Auth._instance;
  }

  public get token(): string | null {
    return this._token;
  }

  public get isLogged(): boolean {
    return this._token !== null;
  }

  public async login(data: PostLoginRequest): Promise<GetLoginResponse> {
    const response = await this._api.post<GetLoginResponse>('/login', data);
    const responseData = response.data;
    if (responseData.success) {
      this._token = responseData.token;
      localStorage.setItem('token', this._token);
      Api.updateInstance();
    }
    return responseData;
  }

  public logout(): void {
    localStorage.removeItem('token');
    this._token = null;
    Api.updateInstance();
  }
}
