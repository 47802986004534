















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ContextFavorecido extends Vue {
  @Prop() public readonly favorecido!: number;
  public novaGuia = false;

  get url(): string {
    return `/indicadores/${this.favorecido}`;
  }

  public navigate(): void {
    const routeData = this.$router.resolve({ path: this.url });

    if (this.novaGuia) {
      window.open(routeData.href, '_blank');
    } else {
      this.$router.push(this.url);
    }
  }

  public hoverNovaGuia(isHovered: boolean): void {
    this.novaGuia = isHovered;
  }
}
