import router from '@/router/index';
import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import Swal from 'sweetalert2';
import Auth from './Auth';

export default class Api {
  public static instance = Api.getInstance();

  public static get baseUrl(): string | undefined {
    return process.env.VUE_APP_API_URL;
  }

  public static updateInstance(): void {
    this.instance = Api.getInstance();
  }

  public static getInstance(): AxiosInstance {
    const instance: AxiosInstance = axios.create({
      baseURL: Api.baseUrl,
      headers: {
        Authorization: Auth.instance.token,
      },
    });

    instance.defaults.paramsSerializer = (p) => {
      return qs.stringify(p, { arrayFormat: 'indices' });
    };

    instance.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        const response = error.response;
        if (response.status === 401) {
          Swal.fire({
            text: 'Sessão inválida',
            toast: true,
            showConfirmButton: false,
            position: 'top-right',
            icon: 'error',
          });
          router.push('/login?invalid');
        }
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );

    return instance;
  }
}
