


















































import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import { Comissoes } from '@/plugins/api';
import { BPopover } from 'bootstrap-vue';

@Component
export default class ExcluirComissao extends Vue {
  @Prop() public readonly comissao!: IComissao;
  @Ref() public readonly popover!: BPopover;
  public processing = false;

  get popoverId(): string {
    return `excluir-comissao-${this.comissao.id}`;
  }

  get disabled(): boolean {
    if (this.processing) return true;
    return ['removido', 'perdido', 'pago'].includes(this.comissao.situacao);
  }

  dismiss(): void {
    this.$root.$emit('bv::hide::popover');
  }

  excluir(): void {
    this.dismiss();
    this.processing = true;

    const data: PatchComissaoRequest = {
      situacao: 'removido',
    };
    Comissoes.patch(this.comissao.id, data)
      .then(() => {
        this.$emit('refresh');
      })
      .catch(() => {
        this.$swal({
          icon: 'error',
          title: 'Falha ao excluir!',
        });
      })
      .finally(() => {
        this.processing = false;
      });
  }
}
