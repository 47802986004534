









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ContextIndicador extends Vue {
  @Prop() public readonly indicador!: string;

  public urlParceiros =
    'https://dashboard.parceirosproteauto.com.br/painel/indicador/perfil.php?id=';
}
