

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class MoskitField extends Vue {
  @Prop() public readonly data!: number;
  @Prop() public readonly loading!: boolean;
}
