






























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class BtnTimed extends Vue {
  @Prop() public readonly size!: string;
  @Prop() public readonly variant!: string;
  @Prop() public readonly disabled!: boolean;
  @Prop({ default: false }) public readonly block!: boolean;
  @Prop({ default: 2000 }) public readonly time!: number;
  public waiting = true;
  public completeSteps = 0;

  public mounted(): void {
    this.completeSteps = 0;
    setTimeout(() => {
      this.completeSteps = 1;
    });
    setTimeout(() => {
      this.waiting = false;
      this.completeSteps = 0;
    }, this.time);
  }

  public onClick() {
    this.$emit('click');
  }
}
