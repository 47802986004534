

























































import { Component, Vue, Prop } from 'vue-property-decorator';
import FiltroId from './FiltroId.vue';
import FiltroDate from './FiltroDate.vue';
import FiltroNumber from './FiltroNumber.vue';
import FiltroTexto from './FiltroTexto.vue';
import FiltroEnum from './FiltroEnum.vue';
import FiltroMultiEnum from './FiltroMultiEnum.vue';
import moment from 'moment';

@Component({
  components: {
    FiltroId,
    FiltroDate,
    FiltroNumber,
    FiltroTexto,
    FiltroEnum,
    FiltroMultiEnum,
  },
})
export default class Filtro extends Vue {
  @Prop() public readonly data!: IFiltroField;
  public open = true;

  public model: IFiltroValues | null = null;

  public close(): void {
    this.open = false;
  }

  filtrar(): void {
    this.$store.commit('SET_FILTRO_VALUE', this.model);
    this.$root.$emit('filtros');
  }

  remove(): void {
    this.$store.commit('REMOVE_FILTRO', this.data.id);
    this.$emit('remove', this.data.id);
  }

  get icon(): string {
    switch (this.data.type) {
      case 'id':
        return 'mdi-identifier';
      case 'number':
        return 'mdi-numeric';
      case 'date':
        return 'mdi-calendar';
      case 'enum':
        return 'mdi-format-list-bulleted';
      case 'multi-enum':
        return 'mdi-tag-multiple-outline';
      default:
      case 'text':
        return 'mdi-text';
    }
  }

  get valor(): string {
    if (this.model !== null) {
      let prefix = '';
      let valor = this.model.value;
      if (Array.isArray(this.model.value)) {
        prefix = 'Entre: ';
        let valorMenor = this.model.value[0];
        let valorMaior = this.model.value[1];
        switch (this.data.type) {
          case 'date':
            valorMenor = moment.unix(valorMenor as number).format('DD/MM/YY');
            valorMaior = moment
              .unix(valorMaior as number)
              .subtract(1, 'days')
              .format('DD/MM/YY');
            if (valorMenor === valorMaior) {
              prefix = 'No dia: ';
              valor = valorMenor;
            } else {
              valor = `${valorMenor} - ${valorMaior}`;
            }
            break;
          case 'number':
            valorMenor = this.formatter(valorMenor as string);
            valorMaior = this.formatter(valorMaior as string);
            valor = `${valorMenor} - ${valorMaior}`;
            break;
          case 'multi-enum':
            prefix = '';
            valor = this.model.value.join(', ');
            break;
        }
      }
      if (this.data.type === 'id') {
        prefix = 'ID: ';
      }
      return `(${prefix}${valor})`;
    }
    return '';
  }

  formatter(value: string): string {
    let valor: string | number = value.replace(/[^\d.,]/g, '');
    valor = valor.split('.').join('');
    const partes = valor.split(',');

    let inteiro: string | number = partes[0];
    inteiro = Intl.NumberFormat('pt-BR', {
      style: 'decimal',
      maximumFractionDigits: 20,
    }).format(parseFloat(inteiro));

    const decimal = partes.length > 1 ? `,${partes[1]}` : '';

    return `${inteiro}${decimal}`;
  }
}
