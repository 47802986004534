
















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class FiltroTexto extends Vue {
  @Prop() public readonly data!: IFiltroField;
  public contem = true;
  public text = '';

  get radioID() {
    return `contem-${this.data.id}`;
  }

  get placeholder() {
    return 'Texto que' + (this.contem ? '' : ' não') + ' deve conter';
  }

  get operator() {
    return this.contem ? 'lk' : 'nlk';
  }

  @Watch('contem')
  @Watch('text')
  input() {
    const filtro: IFiltroValues = {
      field: this.data.id,
      operator: this.operator,
      value: this.text,
    };
    this.$emit('input', filtro);
  }
}
