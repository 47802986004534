
















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class FormatDate extends Vue {
  @Prop() public readonly data!: number | string;
  @Prop({ default: 'DD/MM/YYYY' }) public readonly formato!: string;
  @Prop({ default: '-' }) public readonly text!: string;

  get timestamp(): number {
    if (typeof this.data === 'string') {
      return new Date(this.data).getTime();
    }

    return this.data;
  }
}
