




































































import { Component, Vue, Ref } from 'vue-property-decorator';
import Filtro from './Filtro.vue';

@Component({
  components: {
    Filtro,
  },
})
export default class FiltrosSidebar extends Vue {
  @Ref('filtros') public readonly refFiltros!: Array<Filtro>;
  public backdrop = false;
  public colunaSelecionada = '';

  get colunas(): IFiltroFieldArray {
    return this.$store.getters.colunas;
  }

  get filtros(): IFiltroField[] {
    return this.$store.state.campos;
  }

  get tabela(): string {
    return this.$store.state.tabela;
  }

  created(): void {
    this.backdrop =
      (localStorage.getItem('filter-backdrop') ?? false) === 'true';
    localStorage.setItem('filter-backdrop', this.backdrop ? 'true' : 'false');
  }

  public togglePin(): void {
    this.backdrop = !this.backdrop;
    localStorage.setItem('filter-backdrop', this.backdrop ? 'true' : 'false');
  }

  public addFiltro(item: IFiltroField): void {
    this.$store.commit('ADD_FILTRO', item);
    this.colunaSelecionada = '';
  }

  public removeFiltro(item: string): void {
    this.$store.commit('REMOVE_FILTRO', item);
    this.$root.$emit('filtros');
  }

  public removerTodosFiltros(): void {
    this.$store.commit('CLEAR_FILTROS');
    this.$root.$emit('filtros');
  }

  public collapseFiltros(): void {
    this.refFiltros?.forEach((item: Filtro) => {
      item.close();
    });
  }
}
