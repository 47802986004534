import Api from './Api';

export default class Bancos {
  public static async get(request?: IRequestOptions): Promise<GetBancosResponse> {
    const response = await Api.instance.get<GetBancosResponse>('/bancos', {
      params: request?.params,
    });
    return response.data;
  }
}
