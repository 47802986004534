













import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class FiltroDate extends Vue {
  @Prop() public readonly data!: IFiltroField;
  public value: IPeriodo | null = null;

  @Watch('value')
  input(value: IPeriodo): void {
    if (value !== null) {
      const filtro: IFiltroValues = {
        field: this.data.id,
        operator: 'rng',
        value: [
          moment(value.start.getTime()).unix(),
          moment(value.end.getTime()).add(1, 'days').unix(),
        ],
      };
      this.$emit('input', filtro);
    }
  }
}
