

































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import ExcluirComissao from './ExcluirComissao.vue';
import ReverterComissao from './ReverterComissao.vue';
import VueContext from 'vue-context';
import { Comissoes, Indicador } from '@/plugins/api';
import ContextFavorecido from '@/components/ContextOptions/ContextFavorecido.vue';
import ContextIndicador from '@/components/ContextOptions/ContextIndicador.vue';
import { situacoes } from './tabela';

@Component({
  components: {
    ExcluirComissao,
    ReverterComissao,
    ContextFavorecido,
    ContextIndicador,
  },
})
export default class ComissoesContext extends Vue {
  @Ref() public readonly menu!: VueContext;
  @Ref() public readonly reverter!: ReverterComissao;
  public situacoes = situacoes;

  public open(event: MouseEvent, item: IComissao): void {
    this.menu.open(event, item);
  }

  public get route(): string {
    return this.$route.name as string;
  }

  public refresh(): void {
    this.close();
    this.$emit('refresh');
  }

  public close(): void {
    this.menu.close();
    this.$emit('close');
  }

  public mudarSituacao(id: number, situacao: string): void {
    this.close();

    const data: PatchComissaoRequest = {
      situacao: situacao,
    };
    Comissoes.patch(id, data)
      .then(() => {
        this.$emit('refresh');
      })
      .catch((err) => {
        this.$swal({
          icon: 'error',
          title: err.response.data,
        });
      });
  }

  public recalcularComissao(id: number): void {
    this.close();

    Comissoes.recalcularComissao(id)
      .then((data) => {
        this.$emit('refresh');
        console.log(data);
      })
      .catch((err) => {
        this.$swal({
          icon: 'error',
          title: err.response.data,
        });
      });
  }

  public criarSaqueComissao(id: number): void {
    this.close();

    Comissoes.criarSaque(id)
      .then(() => {
        this.$emit('refresh');
        this.$swal({
          icon: 'success',
          title: 'Saque criado',
        });
      })
      .catch((err) => {
        this.$swal({
          icon: 'error',
          title: err.response.data,
        });
      });
  }

  public criarSaqueIndicador(id: number): void {
    this.close();

    Indicador.criarSaque(id)
      .then(() => {
        this.$emit('refresh');
      })
      .catch((err) => {
        this.$swal({
          icon: 'error',
          title: err.response.data,
        });
      });
  }
}
